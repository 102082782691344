import AFRAME from 'aframe';
import dispose from './dispose';
import dragControls from './drag-controls';
import effects from './effects';
import followBox from './follow-box';
import gltfPart from './gltf-part';
import grid from './grid';
import hdrEnvMap from './hdr-env-map';
import highlightInteractables from './highlight-interactables';
import lookControls from './look-controls';
import movementAnimation from './movement-animation';
import movementAnimationDeprecated from './movement-animation-dep';
import networkedData from './networked-data';
import networkedAudioSource from './networked-audio-source';
import networkedVideoFrame from './networked-video-frame';
import raycasterInteractionHotspot from './raycaster-interaction-hotspot';
import removeGltfPart from './remove-gltf-part';
import paintMeshes from './paint-meshes';
import playerInfo from './player-info';
import playerModel from './player-model';
import proximity from './proximity';
import renderer from './renderer';
import rigAnimation from './rig-animation';
import rotateWithCamera from './rotate-with-camera';
import smartContent from './smart-content';
import smartContentCast from './smart-content-cast';
import smartContentPoster from './smart-content-poster';
import smartContentVideo from './smart-content-video';
import spawnInCircle from './spawn-in-circle';
import srgbTextureEncoding from './srgb-texture-encoding';
import textureMesh from './texture-mesh';
import transformControls from './transform-controls';
import videoTextureMesh from './video-texture-mesh';
import canvasTextureMesh from './canvas-texture-mesh';
import customPortal from './custom-portal';

AFRAME.registerComponent('gltf-part', gltfPart);
AFRAME.registerComponent('grid', grid);
AFRAME.registerComponent('hdr-env-map', hdrEnvMap);
AFRAME.registerComponent('raycaster-interaction-hotspot', raycasterInteractionHotspot);
AFRAME.registerComponent('remove-gltf-part', removeGltfPart);
AFRAME.registerComponent('paint-meshes', paintMeshes);
AFRAME.registerComponent('proximity', proximity);
AFRAME.registerComponent('sr-dispose', dispose);
AFRAME.registerComponent('sr-drag-controls', dragControls);
AFRAME.registerComponent('sr-effects', effects);
AFRAME.registerComponent('sr-follow-box', followBox);
AFRAME.registerComponent('sr-highlight-interactables', highlightInteractables);
AFRAME.registerComponent('sr-look-controls', lookControls);
AFRAME.registerComponent('sr-movement-animation', movementAnimation);
AFRAME.registerComponent('sr-movement-animation-dep', movementAnimationDeprecated);
AFRAME.registerComponent('sr-networked-data', networkedData);
AFRAME.registerComponent('sr-networked-audio-source', networkedAudioSource);
AFRAME.registerComponent('sr-networked-video-frame', networkedVideoFrame);
AFRAME.registerComponent('sr-player-info', playerInfo);
AFRAME.registerComponent('sr-player-model', playerModel);
AFRAME.registerComponent('sr-spawn-in-circle', spawnInCircle);
AFRAME.registerComponent('sr-renderer', renderer);
AFRAME.registerComponent('sr-rig-animation', rigAnimation);
AFRAME.registerComponent('sr-rotate-with-camera', rotateWithCamera);
AFRAME.registerComponent('sr-smart-content', smartContent);
AFRAME.registerComponent('sr-smart-content-cast', smartContentCast);
AFRAME.registerComponent('sr-smart-content-poster', smartContentPoster);
AFRAME.registerComponent('sr-smart-content-video', smartContentVideo);
AFRAME.registerComponent('sr-srgb-texture-encoding', srgbTextureEncoding);
AFRAME.registerComponent('sr-transform-controls', transformControls);
AFRAME.registerComponent('texture-mesh', textureMesh);
AFRAME.registerComponent('video-texture-mesh', videoTextureMesh);
AFRAME.registerComponent('canvas-texture-mesh', canvasTextureMesh);
AFRAME.registerComponent('custom-portal', customPortal);
