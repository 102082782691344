import Vue from 'vue';
import { srVueApp } from '@/main';
import { muteScreenShare } from '@/utils/networking';
import { toggleVideo } from '@/utils/device-controls';

export const smartContentTypeIconMap = {
  poster: 'file-image',
  link: 'web',
  video: 'file-video',
  document: 'file-document',
  cast: 'cast',
};

export const smartContentTypeIconMap3d = {
  cast: 'cast',
};

export const smartContentActions = {
  cast: [
    { icon: 'screen_share', onClick: async (e, asset) => {
      const store = Vue.prototype.$store;
      // prevent screen-sharing if the user is already sharing video (only one video stream can be active at a a time with naf-janus-adapter)
      if (!store.state.gameSettings.isVideoOutputMuted) { return srVueApp.$root.Toast.show({ text: `You cannot share your screen while your camera is active`, timeout: 5000 }); }
      // prevent screen-sharing if the case source element does not exist (it's created in the sr-smart-content-cast component)
      const castingSourceEl = document.getElementById(asset.id);
      if (!castingSourceEl) { return console.warn('smartContentActions::cast source element not found', asset.id); }
      // prevent screen-sharing if the panel is already being shared to and it's not your share, i.e. a networked instance for it already exists in the scene
      const existingCastEl = document.querySelector(`a-scene > [networked][data-networked="${asset.id}"]`);
      const isCastingTo = e.srcElement.hasAttribute('sc-is-casting-to');
      if (existingCastEl && !isCastingTo) { return srVueApp.$root.Toast.show({ text: `This panel already has an ongoing cast session`, timeout: 5000 }); }
      const stopCasting = async () => {
        await muteScreenShare(true);
        store.state.gameSettings.isScreenShareMuted = true;
        e.srcElement.removeAttribute('sc-is-casting-to');
        castingSourceEl.removeAttribute('networked');
        castingSourceEl.removeAttribute('networked-video-source');
        castingSourceEl.setAttribute('visible', false);
      };
      if (isCastingTo) {
        await stopCasting();
      } else {
        try {
          // if not casting to this panel, but casting elsewhere, prevent the share
          if (!store.state.gameSettings.isScreenShareMuted) { return srVueApp.$root.Toast.show({ text: `You already have an ongoing cast session in another panel`, timeout: 5000 }); }
          const stream = await muteScreenShare(false);
          store.state.gameSettings.isScreenShareMuted = false;
          // TODO: check existingCastEl again in case someone stole this panel while waiting for above promise to complete
          // handle when the cast is ended at the browser level
          stream.getVideoTracks().forEach(t => t.onended = stopCasting);
          e.srcElement.setAttribute('sc-is-casting-to', '');
          castingSourceEl.setAttribute('networked', { template: '#sc-cast-template', attachTemplateToLocal: true });
          castingSourceEl.setAttribute('visible', true);
          // networked-video-source uses an incompatible encoding, update to improve screen share colours (this component is synced to fix colours on other clients too)
          castingSourceEl.setAttribute('sr-srgb-texture-encoding', '');
        } catch (e) {
          return console.warn('smartContentActions::cast denied/failed', e);
        }
      }
    }},
    { icon: 'videocam', onClick: async (e, asset) => {
      const store = Vue.prototype.$store;
      // prevent video sharing if the user is already screen sharing
      if (!store.state.gameSettings.isScreenShareMuted) { return srVueApp.$root.Toast.show({ text: `You already have an ongoing cast session`, timeout: 5000 }); }
      // prevent screen-sharing if the case source element does not exist (it's created in the sr-smart-content-cast component)
      const castingSourceEl = document.getElementById(asset.id);
      if (!castingSourceEl) { return console.warn('smartContentActions::cast source element not found', asset.id); }
      // prevent screen-sharing if the panel is already being shared to and it's not your share, i.e. a networked instance for it already exists in the scene
      const existingCastEl = document.querySelector(`a-scene > [networked][data-networked="${asset.id}"]`);
      const isCastingTo = e.srcElement.hasAttribute('sc-is-casting-to');
      if (existingCastEl && !isCastingTo) { return srVueApp.$root.Toast.show({ text: `A cast session is already ongoing on this panel`, timeout: 5000 }); }
      const stopCasting = async () => {
        // toggle video off, if already active
        if (!store.state.gameSettings.isVideoOutputMuted) { await toggleVideo(); }
        store.state.gameSettings.isVideoShareMuted = true;
        e.srcElement.removeAttribute('sc-is-casting-to');
        castingSourceEl.removeAttribute('networked');
        castingSourceEl.removeAttribute('networked-video-source');
        castingSourceEl.setAttribute('visible', false);
      };
      if (isCastingTo) {
        await stopCasting();
      } else {
        // if not casting to this panel, but casting elsewhere, prevent the share
        if (!store.state.gameSettings.isVideoShareMuted) { return srVueApp.$root.Toast.show({ text: `You already have an ongoing cast session in another panel`, timeout: 5000 }); }
        // toggle video on, if not already active
        if (store.state.gameSettings.isVideoOutputMuted) { await toggleVideo(); }
        store.state.gameSettings.isVideoShareMuted = false;
        e.srcElement.setAttribute('sc-is-casting-to', '');
        castingSourceEl.setAttribute('networked', { template: '#sc-cast-template', attachTemplateToLocal: true });
        castingSourceEl.setAttribute('visible', true);
        // networked-video-source uses an incompatible encoding, update to improve screen share colours (this component is synced to fix colours on other clients too)
        castingSourceEl.setAttribute('sr-srgb-texture-encoding', '');
        // detach cast if the video is toggled off
        const videoMuteListener = store.watch((store) => store.gameSettings.isVideoOutputMuted, async () => {
          // store.watch returns the unwatch function, so call is to disable this listener (it only needs to run once)
          videoMuteListener();
          await stopCasting();
        });
      }
    }}
  ],
  video: [
    { icon: 'play_arrow', onClick: (e, asset) => {
      const videoEl = document.getElementById(asset.id);
      const isVideoPlaying = e.srcElement.hasAttribute('sc-video-is-playing');
      if (isVideoPlaying) {
        videoEl.pause();
        e.srcElement.querySelector('.sr-sc-lower-label-action-text').setAttribute('troika-text', { value: 'play_arrow' });
        e.srcElement.removeAttribute('sc-video-is-playing');
      } else {
        videoEl.play();
        e.srcElement.querySelector('.sr-sc-lower-label-action-text').setAttribute('troika-text', { value: 'pause' });
        e.srcElement.setAttribute('sc-video-is-playing', '');
      }
    }},
    { icon: 'skip_previous', onClick: (e, asset) => {
      const videoEl = document.getElementById(asset.id);
      videoEl.currentTime -= 10;
    }},
    { icon: 'skip_next', onClick: (e, asset) => {
      const videoEl = document.getElementById(asset.id);
      videoEl.currentTime += 10;
    }},
    { icon: 'volume_up', onClick: (e, asset) => {
      const videoEl = document.getElementById(asset.id);
      const isVideoMuted = e.srcElement.hasAttribute('sc-video-is-muted');
      if (isVideoMuted) {
        videoEl.muted = false;
        e.srcElement.querySelector('.sr-sc-lower-label-action-text').setAttribute('troika-text', { value: 'volume_up' });
        e.srcElement.removeAttribute('sc-video-is-muted');
      } else {
        videoEl.muted = true;
        e.srcElement.querySelector('.sr-sc-lower-label-action-text').setAttribute('troika-text', { value: 'volume_off' });
        e.srcElement.setAttribute('sc-video-is-muted', '');
      }
    }},
    // { text: '                 00:03:42/00:07:05', onClick: () => {}},
  ],
  poster: [{ icon: 'info_outline' }, { icon: 'file_download' }, { icon: 'add' }, { icon: 'attach_file' }]
};
