import DataObjectParser from 'dataobject-parser';
import Vue from 'vue';
import { CREATE_FILES_SINGLE } from '@/store/files/types';
import * as templates from './form-templates/index.js';
import { isFile } from './files';

export const dynamicFormTemplates = {
  BOOTH: templates['booth'],
  AUDITORIUM_LAYOUT: templates['auditoriumLayout'],
  PERSON: templates['person'],
  CALENDAR_EVENT: templates['calendarEvent'],
  EVENT: templates['event'],
  FEEDBACK: templates['feedback'],
  FLOORMAP_LAYOUT: templates['floormapLayout'],
  LOBBY_LAYOUT: templates['lobbyLayout'],
  LOCATION_CONFIGURATOR: templates['locationConfigurator'],
  LOUNGE_LAYOUT: templates['loungeLayout'],
  CONTENT_LINK: templates['contentLink'],
  CONTENT_PDF: templates['contentPdf'],
  CONTENT_VIDEO: templates['contentVideo'],
  EVENT_BRANDING: templates['eventBranding'],
  PAGE: templates['page'],
  RESOURCE: templates['resource'],
  SITE_BRANDING: templates['siteBranding'],
  SMART_CONTENT_CAST: templates['smartContentCast'],
  SMART_CONTENT_LINK: templates['smartContentLink'],
  SMART_CONTENT_POSTER: templates['smartContentPoster'],
  SMART_CONTENT_VIDEO: templates['smartContentVideo'],
  VIDEO: templates['video'],
  WELCOME_MESSAGE: templates['welcomeMessage'],
};

export const preUploadFormFiles = async (dynamicForm, eventSlug = null) => {
  // TODO: upload all in one go (requires refactor of api to uniquely identify uploaded files)
  const dynamicFormCopy = cloneDynamicForm(dynamicForm);
  for (const formSection of dynamicFormCopy) {
    for (const formField of formSection.formFields) {
      if (isFile(formField.value)) {
        const fileId = await Vue.prototype.$store['dispatch'](CREATE_FILES_SINGLE, { eventSlug, file: formField.value });
        formField.value = fileId;
      }
    }
  }
  return dynamicFormCopy;
};

export const unwrapDynamicFormValues = (dynamicForm) => {
  const parser = new DataObjectParser();
  dynamicForm.forEach((formSection) => {
    formSection.formFields.forEach((formField) => {
      if (!formField.skip) {
        parser.set(formField.property, formField.value);
      }
    });
  });
  return parser.data();
};

export const cloneDynamicForm = (dynamicForm) => {
  return dynamicForm.map((section) => {
    return {
      ...section,
      ...{
        formFields: section.formFields.map((field) => {
          return { ...field };
        })
      }
    };
  });
};

// TODO: this is copy-pasted from dynamicFormValuesToTemplate, needs genericising
export const dynamicFormValuesFromTemplate = (values, template) => {
  const transposedValues = DataObjectParser.untranspose(values);
  const templateCopy = cloneDynamicForm(template);
  templateCopy.forEach((formSection) => {
    formSection.formFields.forEach((formField, index, arr) => {
      // if (skipFields.indexOf(formField.property) > -1) {
      //   return arr.splice(index, 1);
      // }
      if (typeof transposedValues[formField.property] !== 'undefined') {
        if (formField.type === 'datetime') {
          // TODO: abstract this
          formField.value = new Date(transposedValues[formField.property]);
        } else {
          formField.value = transposedValues[formField.property];
        }
        // if (disabledFields.indexOf(formField.property) > -1) {
        //   formField.disabled = true;
        // }
      } else if ((formField.type === 'combo' || formField.type === 'smartcontent') && typeof values[formField.property] !== 'undefined') {
        // the transposed property key for arrays does not match, e.g. values -> values[0], values[1]
        formField.value = values[formField.property];
      } else if (formField.type === 'externalLinkData' && typeof values[formField.type][formField.property] !== 'undefined') {
        // the transposed property key for object does not match, e.g. values -> externalLinkData.facebook
        formField.value = values[formField.type][formField.property];
      }
    });
  });
  return templateCopy;
};

export const dynamicFormValuesToTemplate = (values, templateName, disabledFields = [], skipFields = []) => {
  if (!dynamicFormTemplates[templateName]) { return; }
  if (!values) {
    const templateCopy = cloneDynamicForm(dynamicFormTemplates[templateName]);
    templateCopy.forEach((formSection) => {
      formSection.formFields.forEach((formField, index, arr) => {
        if (skipFields.indexOf(formField.property) > -1) {
          return arr.splice(index, 1);
        } else if (disabledFields.indexOf(formField.property) > -1) {
          formField.disabled = true;
        }
      });
    });
    return templateCopy;
  }
  const transposedValues = DataObjectParser.untranspose(values);
  const templateCopy = cloneDynamicForm(dynamicFormTemplates[templateName]);
  templateCopy.forEach((formSection) => {
    formSection.formFields.forEach((formField, index, arr) => {
      if (skipFields.indexOf(formField.property) > -1) {
        return arr.splice(index, 1);
      }
      if (typeof transposedValues[formField.property] !== 'undefined') {
        if (formField.type === 'datetime') {
          // TODO: abstract this
          formField.value = new Date(transposedValues[formField.property]);
        } else {
          formField.value = transposedValues[formField.property];
        }
        if (disabledFields.indexOf(formField.property) > -1) {
          formField.disabled = true;
        }
      } else if ((formField.type === 'combo' || formField.type === 'smartcontent') && typeof values[formField.property] !== 'undefined') {
        // the transposed property key for arrays does not match, e.g. values -> values[0], values[1]
        formField.value = values[formField.property];
      } else if (formField.type === 'externalLinkData' && typeof values[formField.type][formField.property] !== 'undefined') {
        // the transposed property key for object does not match, e.g. values -> externalLinkData.facebook
        formField.value = values[formField.type][formField.property];
      }
    });
  });
  return templateCopy;
};
export const valuesToDynamicForm = (values1, values2) => {
  const v1 = values1[0].formFields;
  const v2 = values2[0].formFields;
  return v1.map((formField) => {
    const match = v2.find((item) => item.name === formField.name);
    if (match) {
      return {...formField, value: match.value };
    }
    return formField;
  });
  
};

export const valuesToDynamicTemplate = (values, eventBranding) => {
  if (!values) {
    return {
      formFields: []
    };
  }
  function nameToTitle(text) {
    if (!text) {return;}
    text = text.replace('_', ' ');
    text =  text.charAt(0).toUpperCase() + text.slice(1);
    if (text.match(/(\d+)image/)){
      const splitText = text.split(/(\d+)/);
      text = `${splitText[0]} ${splitText[1]}`;
    }
    return text;
  }
  const formFields = values.map((item) => {
    let result = {...item};
    result.property = item.name;
    if (item.type === 'primary' || item.type === 'secondary'){
      result.type = 'color';
      result.title = 'Brand Colour';
      result.classes = 'mb-6';
      // if no default value is set, a color object will be created (hex, hexa, hsl, etc.)
      // the dynamic form does not support this object, so a string value must be explicitly set
      result.value =  result.value ? result.value : eventBranding ? eventBranding.navbar.color : item.type === 'primary' ? '#FFFAAD' : '#6D6C63';
    }
    if (item.type === 'poster' || item.type === 'logo'){
      result.title = nameToTitle(item.name);
      result.caption = item.name.includes('landscape') ? '1400px x 1000px' : '1025px x 255px';
      result.options = {
        aspectRatio: 1/1,
        width: 100,
        placeholderColor: '#e5e5e5',
        placeholderText: '',
        placeholderPath: item.type === 'poster' ? '/img/floor-config-placeholders/Smart_content_landscape.jpg' : '/img/floor-config-placeholders/logo.png',
        cropperSettings: {
          title: `Crop your image`,
          aspectRatio: item.name.includes('landscape') ? 14/10 : 10/14,
        }
      };
      result.type = 'image';
    }
    if (item.type === 'link'){
      result.type = 'text';
      result.caption = 'caption';
      result.title = nameToTitle(item.name) + ' link';
      result.rules = [ value => (value.length === 0 || /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(value)) || `Must be a valid Facebook url` ]
    }
    return result;
  });
  return [{
    formFields
  }];
};

export const getMeshValues = (dataUnwrapped, meshes) => {
  try {
    let savedMeshes = [];
    Object.keys(dataUnwrapped).map( key => {
      let obj = {};
      obj.name = key;
      const dataItemValue = dataUnwrapped[key];
      if (!dataItemValue) { return; }
      obj.value = dataItemValue;

      const findModelItem = meshes.find( (item) =>  item.name === key);
      if (findModelItem && (findModelItem.type === 'poster' || findModelItem.type === 'logo' || findModelItem.type === 'secondary' || findModelItem.type === 'primary')) {
        obj.type = findModelItem && findModelItem.name ? findModelItem.type : undefined;
        savedMeshes.push(obj);
      }
    });
    return savedMeshes;
  } catch (error) {
    console.log('getMeshValues', error);
  }    
};

export const getSingleFormDiff = (form1, form2) => {
  let diff = null;
  Object.keys(form1).forEach((i) => {
    form1[i].formFields.forEach((field) => {
      const form1FieldValue = field.value;
      const form2Field = getFormFieldByProperty(form2, field.property);
      if (JSON.stringify(form1FieldValue) !== JSON.stringify(form2Field.value)) { diff = form2Field; }
    });
  });
  return diff;
};

export const compareFormsForEquality = (array1, array2) => {
  let arraysAreEqual = true;

  function objectsAreSame(x, y) {
    var objectsAreSame = true;
    for (var propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  }

  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2[i];
    if (!objectsAreSame(obj1, obj2)){
      arraysAreEqual = false;
    }
  }
  return arraysAreEqual;
};


export const getFormFieldByProperty = (form, property) => {
  let result = null;
  for (const field of form) {
    result = field.formFields.find(dfs => dfs.property === property);
    if (result) { return result; }
  }
  return result;
};

export const removeFormFieldByProperty = (form, property) => {
  let result = null;
  for (const field of form) {
    const targetIndex = field.formFields.findIndex(dfs => dfs.property === property);
    if (targetIndex > -1) { field.formFields.splice(targetIndex, 1); }
  }
  return result;
};
