import AFRAME from 'aframe';
import { truncateString } from '@/utils/utils';
import { getUploadcareStaticAssetUrl } from '@/utils/files';
import { smartContentActions, smartContentTypeIconMap3d } from '@/utils/smart-content';

export const drawSmartContent = (meshCenter, meshMeta, smartContent, mediaDimensions, asset) => {
  const scContainerEl = drawSmartContentContainer(meshCenter, meshMeta, mediaDimensions);
  const scProximityEl = scContainerEl.querySelector('.sr-sc-proximity-container');
  const scContentEl = scContainerEl.querySelector('.sr-sc-content-container');
  scContentEl.append(drawSmartContentPanel(smartContent, mediaDimensions, asset));
  scProximityEl.append(drawSmartContentUpperLabel(smartContent, mediaDimensions));
  scProximityEl.append(drawSmartContentLowerLabel(smartContent, mediaDimensions, asset));
  return scContainerEl;
};

export const drawSmartContentContainer = (meshCenter, meshMeta, mediaDimensions) => {
  const scContainerEl = document.createElement('a-entity');
  scContainerEl.classList.add('sr-sc-container');
  // scContainerEl.setAttribute('layout', { type: 'line', plane: 'yz', reverse: true, align: 'center', margin: (mediaDimensions.height / 2) + 0.1 });
  scContainerEl.setAttribute('position', `${meshCenter.x} ${meshCenter.y} ${meshCenter.z}`);
  if (meshMeta && meshMeta.rotY) {
    scContainerEl.setAttribute('rotation', `0 ${meshMeta.rotY} 0`);
  }
  const scProximityEl = document.createElement('a-entity');
  scProximityEl.classList.add('sr-sc-proximity-container');
  scProximityEl.setAttribute('visible', false);
  scContainerEl.append(scProximityEl);
  const scContentEl = document.createElement('a-entity');
  scContentEl.classList.add('sr-sc-content-container');
  scContainerEl.append(scContentEl);
  return scContainerEl;
};

export const drawSmartContentUpperLabel = (smartContent, mediaDimensions) => {
  const scUpperLabelEl = document.createElement('a-entity');
  scUpperLabelEl.classList.add('sr-sc-upper-label');
  scUpperLabelEl.setAttribute('position', `0 ${(mediaDimensions.height/2) - 0.15} 0.03`);
  scUpperLabelEl.setAttribute('geometry', { primitive: 'plane', height: '0.32', width: mediaDimensions.width });
  scUpperLabelEl.setAttribute('material', { color: '#FFFFFF', shader: 'flat' });

  const scUpperLabelTextEl = document.createElement('a-entity');
  scUpperLabelTextEl.classList.add('sr-sc-upper-label-text');
  scUpperLabelTextEl.setAttribute('position', `0 0.06 0.01`);
  scUpperLabelTextEl.setAttribute('troika-text', {
    color: 'black',
    value: truncateString(smartContent.content.title, 35),
    maxWidth: mediaDimensions.width - 0.2,
    fontSize: 0.1,
    overflowWrap: 'break-word',
    align: 'center'
  });
  // setRenderOrderSimple(scUpperLabelTextEl, 4);

  const smartContentActions = [{ icon: 'info_outline' }, { icon: 'chat_bubble_outline' }, { icon: 'favorite' }, { icon: 'share' }];
  const scUpperLabelActionsEl = document.createElement('a-entity');
  scUpperLabelActionsEl.classList.add('sr-sc-upper-label-actions');
  scUpperLabelActionsEl.setAttribute('position', `0 -0.06 0.01`);

  smartContentActions.forEach((action) => {
    const scUpperLabelAction = document.createElement('a-entity');
    setRenderOrderSimple(scUpperLabelAction, 4);
    scUpperLabelAction.setAttribute('troika-text', {
      font: 'https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff',
      fontSize: 0.15,
      color: 'black',
      align: 'center',
      value: action.icon
    });
    scUpperLabelActionsEl.append(scUpperLabelAction);
  });

  scUpperLabelEl.append(scUpperLabelTextEl);
  scUpperLabelEl.append(scUpperLabelActionsEl);

  // layouts need to be applied last, they will take initial position into consideration
  scUpperLabelActionsEl.setAttribute('layout', { type: 'line', align: 'center', margin: '0.25' });

  return scUpperLabelEl;
};

export const attachSmartContentProximityEvents = (smartContentContainer) => {
  smartContentContainer.setAttribute('proximity', { target: '#player-model-entity', distance: 8 });
  const scProximityEl = smartContentContainer.querySelector('.sr-sc-proximity-container');
  smartContentContainer.addEventListener('proximity-event', (event) => {
    const scOutlineEl = smartContentContainer.querySelector('.sr-sc-mesh-outline');
    // handle case where outline not rendered yet (user spawned next to smart content and triggered this event early)
    if (!scOutlineEl) { return; }
    if (event.detail.type === 'selected') {
      scOutlineEl.setAttribute('visible', false);
      scProximityEl.setAttribute('visible', true);
    } else {
      scOutlineEl.setAttribute('visible', true);
      scProximityEl.setAttribute('visible', false);
    }
  });
};

export const drawSmartContentLowerLabel = (smartContent, mediaDimensions, asset) => {
  const scLowerLabelEl = document.createElement('a-entity');
  scLowerLabelEl.classList.add('sr-sc-lower-label');
  scLowerLabelEl.setAttribute('position', `0 -${(mediaDimensions.height/2) - 0.15} 0.03`);
  scLowerLabelEl.setAttribute('geometry', { primitive: 'plane', height: 0.31, width: mediaDimensions.width });
  scLowerLabelEl.setAttribute('material', { color: '#FFFFFF', shader: 'flat' });

  const scLowerLabelActionsEl = document.createElement('a-entity');
  scLowerLabelActionsEl.classList.add('sr-sc-lower-label-actions');
  scLowerLabelActionsEl.setAttribute('geometry', { primitive: 'plane', height: 0, width: 0});

  smartContentActions[smartContent.type].forEach((action) => {
    const scLowerLabelAction = document.createElement('a-entity');
    const scLowerLabelActionText = document.createElement('a-entity');
    scLowerLabelActionText.classList.add('sr-sc-lower-label-action-text');
    setRenderOrderSimple(scLowerLabelAction, 1);
    setRenderOrderSimple(scLowerLabelActionText, 1);
    const textOptions = {
      color: 'black',
      fontSize: 0.15,
      value: action.icon ? action.icon : action.text
    };
    if (action.icon) { textOptions.font = 'https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff'; }
    else { textOptions.fontSize = 0.05; }
    scLowerLabelActionText.setAttribute('troika-text', textOptions);
    scLowerLabelAction.append(scLowerLabelActionText);
    scLowerLabelAction.setAttribute('material', { transparent: true, opacity: 0, depthWrite: false });
    scLowerLabelAction.setAttribute('geometry', { primitive: 'circle', radius: 0.1 });
    if (action.onClick) {
      scLowerLabelAction.setAttribute('raycaster-interaction-hotspot', '');
      scLowerLabelAction.addEventListener('click', (e) => action.onClick(e, asset));
    }
    scLowerLabelActionsEl.append(scLowerLabelAction);
  });
  scLowerLabelEl.append(scLowerLabelActionsEl);

  // layouts need to be applied last, they will take initial position into consideration
  scLowerLabelActionsEl.setAttribute('layout', { type: 'line', align: 'center', margin: '0.25' });

  return scLowerLabelEl;
};

export const drawSmartContentPanel = (smartContent, mediaDimensions, asset) => {
  const scContentPanelEl = document.createElement('a-entity');
  scContentPanelEl.classList.add('sr-sc-content-panel');
  let scContentMediaEl = null;
  switch (smartContent.type) {
  case 'poster':
    scContentMediaEl = drawSmartContentPanelPoster(smartContent, mediaDimensions, asset);
    break;
  case 'video':
    scContentMediaEl = drawSmartContentPanelVideo(smartContent, mediaDimensions, asset);
    break;
  case 'cast':
    scContentMediaEl = drawSmartContentPanelCast(smartContent, mediaDimensions, asset);
    break;
  }
  if (scContentMediaEl === null) { return console.warn('[drawSmartContentPanel] Media element not generated', smartContent, asset); }
  scContentPanelEl.append(scContentMediaEl, smartContent.type);
  drawObjectOutline(scContentMediaEl, mediaDimensions, smartContent.type).then((outlineEl) => {
    scContentPanelEl.append(outlineEl);
  });
  return scContentPanelEl;
};

export const drawObjectOutline = async (sourceEl, dimensions, scType) => {
  const sourceObject = await waitFor3DObject(sourceEl);
  const outlineEl = document.createElement('a-entity');
  const outlineMaterial = new AFRAME.THREE.MeshBasicMaterial({ color: 0xffffff });
  const outlineMesh = new AFRAME.THREE.Mesh(sourceObject.geometry, outlineMaterial);

  outlineEl.classList.add('sr-sc-mesh-outline');
  outlineEl.setAttribute('position', sourceEl.getAttribute('position'));
  outlineEl.setAttribute('rotation', sourceEl.getAttribute('rotation'));
  if (dimensions.width > dimensions.height) { outlineEl.setAttribute('scale', '1.02 1.05 1.05'); }
  else { outlineEl.setAttribute('scale', '1.05 1.05 1.02'); }
  if (scType && smartContentTypeIconMap3d[scType]) {
    outlineEl.setAttribute('troika-text', {
      font: 'https://fonts.gstatic.com/s/materialicons/v139/flUhRq6tzZclQEJ-Vdg-IuiaDsNa.woff',
      color: 'black',
      value: smartContentTypeIconMap3d[scType]
    });
  }

  setMeshRenderOrder(outlineEl, 1);
  setMeshRenderOrder(sourceEl, 2);
  setRenderOrderSimple(outlineEl, 1);
  setRenderOrderSimple(sourceEl, 2);
  outlineEl.setObject3D('mesh', outlineMesh);
  return outlineEl;
};

export const drawSmartContentPanelVideo = (smartContent, mediaDimensions, asset) => {
  const existingVideoAssetEl = document.querySelector(`a-assets video#${asset.id}`);
  if (!existingVideoAssetEl) {
    const videoFileSrc = getUploadcareStaticAssetUrl(smartContent.content.file_id);
    const videoEl = document.createElement('video');
    videoEl.setAttribute('id', asset.id);
    videoEl.setAttribute('src', videoFileSrc);
    videoEl.setAttribute('loop', true);
    document.querySelector('a-assets').append(videoEl);
  }

  const videoPlaneEl = document.createElement('a-video');
  videoPlaneEl.classList.add('sr-sc-content-video');
  videoPlaneEl.setAttribute('src', `#${asset.id}`);
  videoPlaneEl.setAttribute('height', mediaDimensions.height);
  videoPlaneEl.setAttribute('width', mediaDimensions.width);

  return videoPlaneEl;
};

export const drawSmartContentPanelPoster = (smartContent, mediaDimensions, asset) => {
  // create the plane and its components
  const geometry = new AFRAME.THREE.PlaneGeometry(mediaDimensions.width, mediaDimensions.height);
  const material = new AFRAME.THREE.MeshBasicMaterial({ map: asset.texture });
  const mesh = new AFRAME.THREE.Mesh(geometry, material);
  material.side = AFRAME.THREE.DoubleSide;
  material.transparent = true;

  const posterPlaneEl = document.createElement('a-entity');
  posterPlaneEl.setObject3D('mesh', mesh);

  return posterPlaneEl;
};

export const drawSmartContentPanelCast = (smartContent, mediaDimensions, asset) => {
  const castPlanePlaceholderEl = document.createElement('a-plane');
  castPlanePlaceholderEl.classList.add('sr-sc-content-cast-placeholder');
  castPlanePlaceholderEl.setAttribute('width', mediaDimensions.width);
  castPlanePlaceholderEl.setAttribute('height', mediaDimensions.height);
  castPlanePlaceholderEl.setAttribute('color', '#3D3D3D');

  // this element will be networked, so its components will be synced (we need to define explicit position,rotation,etc.)
  const castPlaneSourceEl = document.createElement('a-plane');
  castPlaneSourceEl.classList.add('sr-sc-content-cast-source');
  castPlaneSourceEl.setAttribute('id', asset.id);
  castPlaneSourceEl.setAttribute('visible', false);
  castPlaneSourceEl.setAttribute('rotation', asset.rotation);
  castPlaneSourceEl.setAttribute('position', `${asset.center.x} ${asset.center.y} ${asset.center.z}`);
  castPlaneSourceEl.setAttribute('width', mediaDimensions.width);
  castPlaneSourceEl.setAttribute('height', mediaDimensions.height);
  castPlaneSourceEl.setAttribute('material', { shader: 'flat', transparent: true });
  castPlaneSourceEl.setAttribute('sr-networked-data', asset.id);

  document.querySelector('a-scene').appendChild(castPlaneSourceEl);
  return castPlanePlaceholderEl;
};

export const setRenderOrderSimple = (el, renderOrder) => {
  const initialPosition = el.getAttribute('position');
  if (!initialPosition) {
    el.setAttribute('position', `0 0 ${(0.01 * renderOrder)}`);
  } else if (typeof initialPosition === 'string') {
    const initialZPosition = initialPosition.split(' ')[2];
    if (!initialZPosition) { el.setAttribute('position', `0 0 ${initialZPosition + (0.01 * renderOrder)}`); }
  } else if (typeof initialPosition.z !== 'undefined') {
    el.setAttribute('position', `0 0 ${initialPosition.z + (0.01 * renderOrder)}`);
  }
};

export const setMeshRenderOrder = async (nodeOrObject, renderOrder) => {
  if (nodeOrObject.isObject3D) {
    nodeOrObject.material.depthWrite = false;
    nodeOrObject.material.renderOrder = renderOrder;
  } else {
    const object3D = await waitFor3DObject(nodeOrObject);
    object3D.material.depthWrite = false;
    object3D.material.renderOrder = renderOrder;
  }
};

export const waitFor3DObject = (element) => {
  return new Promise((resolve, reject) => {
    const intervalTimeout = 50;
    let intervalCounter = 0;
    const intervalFn = setInterval(() => {
      if (element.getObject3D('mesh')) {
        clearInterval(intervalFn);
        return resolve(element.getObject3D('mesh'));
      }
      intervalCounter++;
      if (intervalCounter >= intervalTimeout) {
        console.warn('[waitFor3DObject] Failed to find the mesh for this node', element);
        clearInterval(intervalFn);
        return reject(element);
      }
    }, 100);
  });
};

export const getContentPlaneDimensionsFromFixedMesh = (fixedMeshBoundingBox, resolution) => {
  const fixedMeshDimensions = getFixedMeshDimensions(fixedMeshBoundingBox);

  // determine the scale factor required for the texture to fit the fixed mesh dimensions
  const scale = Math.min(fixedMeshDimensions.width/resolution.width, fixedMeshDimensions.height/resolution.height);

  return { width: resolution.width * scale, height: resolution.height * scale };
};

export const getFixedMeshDimensions = (fixedMeshBoundingBox) => {
  // depending on rotation, the mesh's width will be defined by its x or z length (whichever is larger)
  const meshWidth = Math.max(fixedMeshBoundingBox.max.x - fixedMeshBoundingBox.min.x, fixedMeshBoundingBox.max.z - fixedMeshBoundingBox.min.z);
  const meshHeight = fixedMeshBoundingBox.max.y - fixedMeshBoundingBox.min.y;

  return { width: meshWidth, height: meshHeight };
};
