import { ADD_GAME_SETTING_EVENT_LISTENER, RESET_GAME_SETTING_STATE } from '@/store/game-settings/types';
import { getInternalAssetUrl, getStaticAssetUrl } from '@/utils/files';
import { onAdapterReady, cleanupLocalMedia } from '@/utils/networking';
import merge from 'deepmerge';
import AFRAME from 'aframe';

const EL_REF_TAGS = {
  SCENE: 'managed-scene',
  CURSOR: 'player-cursor-entity',
  CAMERA: 'camera-entity',
  PLAYER_MODEL: 'player-model-entity',
  PLAYER_AVATAR: 'player-avatar-entity',
  ENVIRONMENT: 'environment-entity',
  ENVIRONMENT_MODEL: 'environment-model-entity',
  NAVMESH: 'navmesh-entity',
  PORTAL: 'portal-entity',
  NAMETAG: 'nametag-entity'
};

const DEFAULT_COMPONENTS_SKY = {};

const DEFAULT_COMPONENTS_PORTAL = {};

const DEFAULT_COMPONENTS_CURSOR = {
  'id': 'cursor',
  'cursor': { mouseCursorStylesEnabled: true, rayOrigin: 'mouse' },
  'raycaster': { far: 20, objects: '.raycastable, [link]' }
};

const DEFAULT_COMPONENTS_CAMERA_RIG = {
  'id': 'camera-rig',
  'sr-follow-box': { target: '#player-model-entity' },
  'look-controls': {}
};

const DEFAULT_COMPONENTS_CAMERA = {
  'id': 'camera-entity',
  'position': '0 1.87 2',
  'camera': {}
};

const DEFAULT_COMPONENTS_PLAYER_MODEL = {
  'id': EL_REF_TAGS.PLAYER_MODEL,
  'movement-controls': { speed: 0.3, camera: '#camera-entity', constrainToNavMesh: true },
  'sr-rotate-with-camera': {},
  'sr-look-controls': {},
  'sr-movement-animation': {},
  'sr-rig-animation': { remoteId: 'animationset-m', clip: 'IDLE', loop: 'repeat', crossFadeDuration: 0.4 },
  // 'sr-spawn-in-circle': { radius: 2 },
  'networked': { template: '#avatar-template', attachTemplateToLocal: false }
};

const DEFAULT_COMPONENTS_PLAYER_AVATAR = {
  'rotation': '0 180 0'
};

const DEFAULT_COMPONENTS_ENVIRONMENT = {
  'id': EL_REF_TAGS.ENVIRONMENT
};

const DEFAULT_COMPONENTS_ENVIRONMENT_MODEL = {
  'id': EL_REF_TAGS.ENVIRONMENT_MODEL
};

const DEFAULT_COMPONENTS_NAVMESH = {
  'id': EL_REF_TAGS.NAVMESH,
  'nav-mesh': {},
  'position': '0 -0.12 0',
  'visible': 'false'
};

const DEFAULT_COMPONENTS_LIGHT_AMBIENT = {
  'light': { type: 'ambient', color: '#FFFFFF', intensity: 0 }
};

const DEFAULT_COMPONENTS_SCENE = {
  'renderer': { colorManagement: true, logarithmicDepthBuffer: true },
  'sr-renderer': {},
  // 'sr-effects': {},
  'hdr-env-map': { path: getInternalAssetUrl('/assets/hdr/ulmer_muenster_1k.hdr'), isSetBackground: false },
  'draco-decoder-path': 'https://www.gstatic.com/draco/v1/decoders/',
  'vr-mode-ui': { enabled: false }
};

export default {
  state: () => ({
    sceneElRefs: {},
    targetDocument: null,
    activeSceneEl: null,
    entityComponentsOverride: {},
    environmentMediaFrames: [],
    sceneModels: [],
    scenePortals: [],
    sceneSeatingMesh: null
  }),
  getters: {
    elRefByName: (state) => (value) => {
      return state.sceneElRefs[value];
    },
    activeSceneEl(state) {
      return state.activeSceneEl;
    },
    targetDocument(state) {
      return state.targetDocument;
    },
    entityComponent: (state) => (value, defaultEntityComponents) => {
      if (state.entityComponentsOverride[value]) {
        return merge(defaultEntityComponents, state.entityComponentsOverride[value]);
      } else if (state.entityComponentsOverride[value] === null) {
        return {};
      }
      return defaultEntityComponents;
    },
    environmentMediaFrames(state) {
      return state.environmentMediaFrames;
    },
    sceneModels(state) {
      return state.sceneModels;
    },
    scenePortals(state) {
      return state.scenePortals;
    },
    sceneSeatingMesh(state) {
      return state.sceneSeatingMesh;
    }
  },
  mutations: {
    setEntityComponentOverride(state, { key, value }) {
      state.entityComponentsOverride[key] = value;
    },
    setActiveTargetDocument(state, value) {
      state.targetDocument = value;
    },
    setActiveSceneEl(state, value) {
      state.activeSceneEl = value;
    },
    setSceneElRef(state, { key, value }) {
      state.sceneElRefs[key] = value;
    },
    resetEntityComponentsOverride(state) {
      state.entityComponentsOverride = {};
    },
    resetSceneElRefs(state) {
      state.sceneElRefs = {};
    },
    setEnvironmentMediaFrames(state, value) {
      state.environmentMediaFrames = value;
    },
    setSceneModels(state, value) {
      state.sceneModels = value;
    },
    setScenePortals(state, value) {
      state.scenePortals = value;
    },
    setSceneSeating(state, value) {
      state.sceneSeatingMesh = value;
    }
  },
  actions: {
    /**
     * 
     * @param { commit, dispatch }
     * @param {{ targetDocument: Document, targetSceneAnchor: Element }} 
     */
    async buildScene({ commit, dispatch, getters }, { targetDocument, targetSceneAnchor }) {
      /**
       * dynamic values:
       * - avatar initial position
       * - environment rotation
       * - environment model/navmesh
       * - environment meshes (mediaframes)
       * - environment textures/theme
       * - environment entities (e.g. booths)
       *     - entity meshes (mediaframes)
       *     - entity textures/theme
       * - enable networking
      **/

      const sceneEl = targetDocument.createElement('a-scene');
      commit('setActiveTargetDocument', targetDocument);
      commit('setActiveSceneEl', sceneEl);

      dispatch('updateEntityComponents', { entity: sceneEl, componentMap: getters.entityComponent('scene', DEFAULT_COMPONENTS_SCENE) });
      commit('setSceneElRef', { key: EL_REF_TAGS.SCENE, value: sceneEl });

      const assetCacheEl = await dispatch('buildAssetCache');
      sceneEl.appendChild(assetCacheEl);

      //Ambient Light
      const lightEl = await dispatch('buildEntity', { components: getters.entityComponent('light', DEFAULT_COMPONENTS_LIGHT_AMBIENT) });
      sceneEl.appendChild(lightEl);

      const skyEl = await dispatch('buildPrimitive', { tag: 'a-sky', components: getters.entityComponent('sky', DEFAULT_COMPONENTS_SKY) }); 
      sceneEl.appendChild(skyEl);

      sceneEl.addEventListener('loaded', async () => {
        commit('SET_SCENE_LOADING_PROGRESS', 20, { root: true });
        const animationSetsEl = await dispatch('buildAnimationSets');
        sceneEl.appendChild(animationSetsEl);
        commit('SET_SCENE_LOADING_PROGRESS', 50, { root: true });

        // player rig needs to be loaded after animationsets, 'sr-rig-animation' depends on it
        const playerRigEl = await dispatch('buildPlayerRig');
        sceneEl.appendChild(playerRigEl);
        commit('SET_SCENE_LOADING_PROGRESS', 65, { root: true });
        
        // environment needs to be loaded after player rig, 'look-at' depends on it
        const environmentEl = await dispatch('buildEnvironment');
        sceneEl.appendChild(environmentEl);
        commit('SET_SCENE_LOADING_PROGRESS', 75, { root: true });

        // TODO: actual loading progress: https://davranetworks.atlassian.net/browse/SR-1711
        // scene ready (1)
        // env model loaded (1)
        // env model textures loaded (environmentMediaFrames.length)
        // scene models loaded (sceneModels.length)
        // scene models texture loaded (sceneModels.length * n [n = sceneModel.mediaFrameMeshes.length])
        // increment for UX, should have a better incrementor in future
        setTimeout(() => commit('SET_SCENE_LOADING_PROGRESS', 95, { root: true }), 200); 
        setTimeout(() => commit('SET_SCENE_LOADING_PROGRESS', 96, { root: true }), 750);    
        setTimeout(() => commit('SET_SCENE_LOADING_PROGRESS', 99, { root: true }), 2000);  
        // timeout for safety, maybe be assets loading
        setTimeout(() => {
          commit('SET_SCENE_LOADING_PROGRESS', 100, { root: true });
        }, 4000);
      }, { once: true });
      targetSceneAnchor.appendChild(sceneEl);
      targetSceneAnchor.style.height = '100%';
      dispatch('updateEntityComponents', { entity: sceneEl, componentMap: { 'embedded': '' }});
    },
    async buildNetworkingDom({ dispatch, getters, rootGetters }, { networkedSceneOptions, nafInstance, userData, isRetryInstance }) {
      if (!isRetryInstance) {
        getters.activeSceneEl.addEventListener('adapter-ready', onAdapterReady);
        nafInstance.schemas.add({
          template: '#avatar-template',
          components: ['position', 'rotation', 'sr-networked-data', 'sr-networked-video-frame', 'sr-player-info', 'sr-player-model', 'sr-rig-animation']
        });
        nafInstance.schemas.add({
          template: '#sc-cast-template',
          components: ['position', 'rotation', 'height', 'width', 'sr-networked-data', 'sr-srgb-texture-encoding']
        });
      }
      getters.elRefByName('player-model-entity').removeAttribute('sr-networked-data');
      getters.elRefByName('player-model-entity').removeAttribute('sr-networked-video-frame');
      getters.elRefByName('player-model-entity').removeAttribute('networked');
      getters.elRefByName('player-model-entity').removeAttribute('sr-player-model');
      getters.activeSceneEl.removeAttribute('networked-scene');
      getters.elRefByName('player-model-entity').setAttribute('sr-networked-data', JSON.stringify({ user: userData }));
      getters.elRefByName('player-model-entity').setAttribute('sr-networked-video-frame', { isFrameVisible: false, isMicVisible: true, isUserSpeaking: false, playerId: userData.id, playerName: userData.displayName, playerCompany: 'Davra', playerRole: 'Company Role', micImageVideo: getInternalAssetUrl(`/img/name-tag/microphone-off.png`), likeImageVideo: getInternalAssetUrl(`/img/name-tag/heart.png`), micImageLabel: getInternalAssetUrl(`/img/name-tag/microphone-off.png`), likeImageLabel: getInternalAssetUrl(`/img/name-tag/heart.png`) });
      // getters.elRefByName('player-model-entity').setAttribute('sr-player-info', { playerName: userData.displayName, playerCompany: 'Davra', playerRole: 'Company Role', micImage: getInternalAssetUrl(`/img/name-tag/microphone-off.png`), likeImage: getInternalAssetUrl(`/img/name-tag/heart.png`)});
      getters.elRefByName('player-model-entity').setAttribute('networked', { template: '#avatar-template', attachTemplateToLocal: false });
      getters.elRefByName('player-model-entity').setAttribute('sr-player-model', { src: rootGetters.rpmAvatarFullUrl });
      getters.activeSceneEl.setAttribute('networked-scene', networkedSceneOptions);
      dispatch(ADD_GAME_SETTING_EVENT_LISTENER, { name: 'on-audio-output-mute', listener: (isAudioMuted) => {
        getters.elRefByName('player-model-entity').setAttribute('sr-networked-video-frame', { isMicVisible: isAudioMuted });
      }}, { root: true });
      dispatch(ADD_GAME_SETTING_EVENT_LISTENER, { name: 'on-video-output-mute', listener: (isVideoMuted) => {
        getters.elRefByName('player-model-entity').setAttribute('sr-networked-video-frame', { isFrameVisible: !isVideoMuted });
      }}, { root: true });
      dispatch(ADD_GAME_SETTING_EVENT_LISTENER, { name: 'on-speech-start', listener: (isUserSpeaking) => {
        getters.elRefByName('player-model-entity').setAttribute('sr-networked-video-frame', { isUserSpeaking: isUserSpeaking });
      }}, { root: true });
    },
    async buildAssetCache({ getters }) {
      const assetCacheEl = getters.targetDocument.createElement('a-assets');
      const animationSetMCacheEl = getters.targetDocument.createElement('a-asset-item');
      animationSetMCacheEl.setAttribute('id', 'asset-animationset-m');
      animationSetMCacheEl.setAttribute('src', getInternalAssetUrl('/assets/models/animationset-m.glb'));
      const animationSetFCacheEl = getters.targetDocument.createElement('a-asset-item');
      animationSetFCacheEl.setAttribute('id', 'asset-animationset-f');
      animationSetFCacheEl.setAttribute('src', getInternalAssetUrl('/assets/models/animationset-f.glb'));
      assetCacheEl.appendChild(animationSetMCacheEl);
      assetCacheEl.appendChild(animationSetFCacheEl);
      return assetCacheEl;
    },
    async buildAnimationSets({ getters }) {
      const animationSetEl = getters.targetDocument.createElement('a-entity');
      const animationSetMEl = getters.targetDocument.createElement('a-entity');
      animationSetMEl.setAttribute('id', 'animationset-m');
      animationSetMEl.setAttribute('gltf-model', '#asset-animationset-m');
      const animationSetFEl = getters.targetDocument.createElement('a-entity');
      animationSetFEl.setAttribute('id', 'animationset-f');
      animationSetFEl.setAttribute('gltf-model', '#asset-animationset-f');
      animationSetEl.appendChild(animationSetMEl);
      animationSetEl.appendChild(animationSetFEl);
      return animationSetEl;
    },
    async buildSceneModel({ dispatch, getters }, sceneModel) {
      const modelEl = await dispatch('buildEntity', { components: {
        'gltf-model': sceneModel.src,
        'position': sceneModel.position,
        'rotation': sceneModel.rotation,
        'proximity': { target: '#player-model-entity', distance: 7 },
        'paint-meshes': { meshDictList: sceneModel.paintableMeshes },
        'remove-gltf-part': { targets: sceneModel.mediaFrameMeshes.map(m => m.name).join() }
      }, elRefTag: 'scene-entity-' + sceneModel.id });
      if (sceneModel.onProximityTrigger) {
        modelEl.addEventListener('proximity-event', (event) => {
          sceneModel.onProximityTrigger(event.detail.type === 'selected');
          if (event.detail.type === 'selected') {
            modelEl.setAttribute('sr-highlight-interactables', '');
          } else {
            modelEl.removeAttribute('sr-highlight-interactables');
          }
        });
      }

      const controlsEl = await dispatch('buildEntity', {
        components: getters.entityComponent('transform-controls', {
          'sr-transform-controls': { targetEl: '#scene-entity-' + sceneModel.id, enabled: false }
        }),
        elRefTag: 'scene-entity-controls-' + sceneModel.id
      });

      if (sceneModel.onTransformStarted !== undefined) { controlsEl.addEventListener('transform-started', sceneModel.onTransformStarted); }
      if (sceneModel.onTransformConfirmed !== undefined) { controlsEl.addEventListener('transform-confirmed', () => sceneModel.onTransformConfirmed(modelEl.object3D)); }

      const labelEl = await dispatch('buildPrimitive', { tag: 'a-troika-text', components: {
        'position': '0 8 0',
        'scale': '6 6 6',
        'outline-width': '0.025',
        'color': 'white',
        // 'color': sceneModel.data.boothStatus === 'open' ? '#03a60a' : '#9e9e9e',
        'value': sceneModel.name,
        'look-at': '#camera-entity',
      }});
      modelEl.appendChild(labelEl);

      sceneModel.mediaFrameMeshes.forEach(async (mesh) => {
        const backwardsCompatibleSrc = mesh.src.indexOf('https://') === 0 || mesh.src.indexOf('http://') === 0
          ? mesh.src : getStaticAssetUrl(mesh.src);
        const meshEl = await dispatch('buildEntity', { components: {
          'texture-mesh': { src: backwardsCompatibleSrc },
          'gltf-part': { src: sceneModel.src, part: mesh.name },
          // TODO: make "smart content" clickable, not "poster"
          'raycaster-interaction-hotspot': mesh.type === 'poster' ? { meshLabelText: 'Expand' } : null
        }, elRefTag: 'scene-entity-' + sceneModel.id + '-frame-' + mesh.name });
        // TODO: make "smart content" clickable, not "poster"
        if (mesh.type === 'poster') {
          meshEl.addEventListener('click', () => { sceneModel.onMeshInteraction(mesh); });
        }
        modelEl.appendChild(meshEl);
      });
      return { modelEl, controlsEl };
    },
    async loadEnvironmentMediaFrames({ dispatch, getters }, { envModelSrc, targetEl }) {
      const mediaFrameEls = [];
      if (targetEl) { targetEl.setAttribute('remove-gltf-part', { targets: getters.environmentMediaFrames.map(mf => mf.name).join() }); }
      getters.environmentMediaFrames.forEach(async (mediaFrame, i) => {
        const existingFrameEl = getters.elRefByName('scene-env-frame-' + mediaFrame.name);
        if (!mediaFrame.value && !existingFrameEl) { return; }
        const meshEl = existingFrameEl ? existingFrameEl : await dispatch('buildEntity', { elRefTag: 'scene-env-frame-' + mediaFrame.name });
        if (mediaFrame.behaviour === 'static') {
          await dispatch('updateEntityComponents', { entity: meshEl, componentMap: {
            'texture-mesh': { src: mediaFrame.value },
            'gltf-part': { src: envModelSrc, part: mediaFrame.name }
          }});
        } else {
          await dispatch('updateEntityComponents', { entity: meshEl, componentMap: {
            'sr-smart-content': { smartContent: mediaFrame.value, meta: mediaFrame.meta },
            'gltf-part': { src: envModelSrc, part: mediaFrame.name },
          }});
        }
        if (!existingFrameEl && targetEl) { targetEl.append(meshEl); }
        mediaFrameEls.push(meshEl);
      });
      return mediaFrameEls;
    },
    async buildEnvironment({ dispatch, getters }) {
      const environmentContainerEl = await dispatch('buildEntity', { components: getters.entityComponent('environment', DEFAULT_COMPONENTS_ENVIRONMENT), elRefTag: EL_REF_TAGS.ENVIRONMENT });
      const environmentModelEl = await dispatch('buildEntity', { components: getters.entityComponent('environment-model', DEFAULT_COMPONENTS_ENVIRONMENT_MODEL), elRefTag: EL_REF_TAGS.ENVIRONMENT_MODEL });
      const environmentNavmeshEl = await dispatch('buildEntity', { components: getters.entityComponent('environment-navmesh', DEFAULT_COMPONENTS_NAVMESH), elRefTag: EL_REF_TAGS.NAVMESH });

      if (getters.sceneSeatingMesh){
        const sceneSeatingMeshEl = await dispatch('buildEntity', { components: { 'gltf-model': getInternalAssetUrl('/assets/models/Seats.glb'), position: '0 0 0' }, elRefTag: 'scene-seating-mesh' });
        environmentContainerEl.appendChild(sceneSeatingMeshEl);
        
        sceneSeatingMeshEl.addEventListener('model-loaded', function() {
          this.setAttribute('visible', false);
          this.object3D.traverse( async function( object ) {
            if ( object.isMesh ) { 
              const seatEl = await dispatch('buildEntity', { components: { 'id': object.name }, elRefTag: object.name });
              seatEl.setObject3D('mesh', object);
              seatEl.classList.add('raycastable');
              seatEl.addEventListener('click', (e)=>{
                function animateAlongNavmesh(targetEntity, targetDestination, selfModelEntity, exitRotation) {
                  const initialMovementControls = targetEntity.getAttribute('movement-controls');
                  const onNavigationStart = () => {
                    if (selfModelEntity) {
                      targetEntity.removeAttribute('movement-controls');
                      targetEntity.removeAttribute('sr-movement-animation');
                      selfModelEntity.setAttribute('rotation', '0 0 0');
                    }
                    targetEntity.removeAttribute('movement-controls');
                    targetEntity.removeAttribute('sr-movement-animation');
                    setTimeout(() => {
                      targetEntity.setAttribute('sr-rig-animation', {
                        clip: 'WALKING',
                        loop: 'repeat',
                        crossFadeDuration: 0.4,
                      });
                    });
                  };
                  const onNavigationEnd = function () {
                    if (selfModelEntity) {
                      // handle self navigation end
                      selfModelEntity.setAttribute('rotation', '0 180 0');
                      if (initialMovementControls) { targetEntity.setAttribute('movement-controls', initialMovementControls); }
                      targetEntity.setAttribute('sr-movement-animation', { staticClip: 'IDLE' });
                    }
                    setTimeout(() => { targetEntity.setAttribute('sr-movement-animation', `staticClip: STAND_TO_SIT;`); },500);
                    if (exitRotation !== undefined) { 
                      targetEntity.setAttribute('rotation', `0 ${exitRotation} 0`); 
                      document.getElementById('camera-rig').components['look-controls'].yawObject.rotation.set(0, 0, 0);
                    }
                    cleanupEventListeners();
                  };
                  const cleanupEventListeners = () => {
                    targetEntity.removeEventListener('navigation-start', onNavigationStart);
                    targetEntity.removeEventListener('navigation-end', onNavigationEnd);
                  };
                  targetEntity.addEventListener('navigation-start', onNavigationStart);
                  targetEntity.addEventListener('navigation-end', onNavigationEnd);
                  targetEntity.setAttribute('nav-agent', { active: true, destination: targetDestination, speed: 4 });
                }
                const mesh = e.target.object3D;
                const meshBoundingBox = new AFRAME.THREE.Box3().setFromObject(mesh);
                const meshCenter = new AFRAME.THREE.Vector3();
                const pos = meshBoundingBox.getCenter(meshCenter);
                animateAlongNavmesh(document.getElementById('player-model-entity'), { x: pos.x, y: pos.y - 0.570, z: pos.z -0.640 }, document.getElementById('player-avatar-entity'), 180);
              });
              sceneSeatingMeshEl.appendChild(seatEl);
            }    
          } );
        }, { once: true });
      }

      // TODO: make this re-usable for booths/sub-entities ?
      if (getters.environmentMediaFrames.length > 0) {
        const envModelSrc = getters.entityComponent('environment-model', DEFAULT_COMPONENTS_ENVIRONMENT_MODEL) &&
          getters.entityComponent('environment-model', DEFAULT_COMPONENTS_ENVIRONMENT_MODEL)['gltf-model']
          ? getters.entityComponent('environment-model', DEFAULT_COMPONENTS_ENVIRONMENT_MODEL)['gltf-model']
          : '';
        await dispatch('loadEnvironmentMediaFrames', { envModelSrc, targetEl: environmentModelEl });
      }

      if (getters.sceneModels.length > 0) {
        getters.sceneModels.forEach(async (sceneModel, i) => {
          const { modelEl, controlsEl } = await dispatch('buildSceneModel', sceneModel);
          environmentContainerEl.appendChild(modelEl);
          environmentContainerEl.appendChild(controlsEl);
        });
      }

      if (getters.scenePortals.length > 0) {
        getters.scenePortals.forEach(async (portal) => {
          // Door model
          const environmentPortalModel = await dispatch('buildEntity', { components: getters.entityComponent('environment-portal', DEFAULT_COMPONENTS_PORTAL), elRefTag: EL_REF_TAGS.PORTAL });
          environmentPortalModel.setAttribute('rotation', portal.modelRotation);
          environmentPortalModel.setAttribute('position', portal.modelPosition);
          environmentPortalModel.setAttribute('scale', '1.2 1.2 1.2');
          // Door Link
          const portalEl = await dispatch('buildPrimitive', { tag: 'a-entity', components: portal });
          portalEl.setAttribute('scale', '0.4 0.4 0.4');
          portalEl.setAttribute('rotation', '0 -90 0');
          portalEl.setAttribute('position', '-0.05 1.6 0');
          // Link Title
          const nametagEl = getters.targetDocument.createElement('a-troika-text');
          nametagEl.setAttribute('scale', '1.5 1.5 1.5');
          nametagEl.setAttribute('rotation', '0 -90 0');
          nametagEl.setAttribute('position', '0 2.6 0');
          nametagEl.setAttribute('outline-width', '0.008');
          nametagEl.setAttribute('value', portal.title);
          // Append model and title to scene
          environmentPortalModel.appendChild(portalEl);
          environmentPortalModel.appendChild(nametagEl);
          environmentContainerEl.appendChild(environmentPortalModel);
        });
      }

      environmentContainerEl.appendChild(environmentModelEl);
      environmentContainerEl.appendChild(environmentNavmeshEl);
      return environmentContainerEl;
    },
    async buildPlayerRig({ dispatch, getters }) {
      const playerRigEl = getters.targetDocument.createElement('a-entity');
      const cursorEl = await dispatch('buildEntity', { components: getters.entityComponent('cursor', DEFAULT_COMPONENTS_CURSOR), elRefTag: EL_REF_TAGS.CURSOR });
      const cameraRigEl = await dispatch('buildEntity', { components: getters.entityComponent('camera-rig', DEFAULT_COMPONENTS_CAMERA_RIG) });
      const cameraEl = await dispatch('buildEntity', { components: getters.entityComponent('camera', DEFAULT_COMPONENTS_CAMERA), elRefTag: EL_REF_TAGS.CAMERA });
      const playerModelEl = await dispatch('buildEntity', { components: getters.entityComponent('player-model', DEFAULT_COMPONENTS_PLAYER_MODEL), elRefTag: EL_REF_TAGS.PLAYER_MODEL });
      const avatarEl = await dispatch('buildEntity', { components: getters.entityComponent('player-avatar', DEFAULT_COMPONENTS_PLAYER_AVATAR), elRefTag: EL_REF_TAGS.PLAYER_AVATAR });

      playerModelEl.appendChild(avatarEl);
      cameraRigEl.appendChild(cameraEl);
      playerRigEl.appendChild(cursorEl);
      playerRigEl.appendChild(cameraRigEl);
      playerRigEl.appendChild(playerModelEl);
      return playerRigEl;
    },
    async buildPrimitive({ getters, dispatch }, { tag, components }) {
      const primitiveEl = getters.targetDocument.createElement(tag);
      await dispatch('updateEntityComponents', { entity: primitiveEl, componentMap: components });
      return primitiveEl;
    },
    async buildEntity({ getters, commit, dispatch }, { components, elRefTag }) {
      const entityEl = getters.targetDocument.createElement('a-entity');
      if (components) { await dispatch('updateEntityComponents', { entity: entityEl, componentMap: components }); }
      if (elRefTag && elRefTag.length > 0) { entityEl.id = elRefTag; commit('setSceneElRef', { key: elRefTag, value: entityEl }); }
      return entityEl;
    },
    updateEntityComponents(_, { entity, componentMap }) {
      Object.keys(componentMap).forEach((key) => {
        const compValues = componentMap[key];
        if (compValues === null) { return entity.removeAttribute(key); }
        if (typeof compValues !== 'object') {
          entity.setAttribute(key, compValues);
        } else if (Object.keys(compValues).length === 0) {
          entity.setAttribute(key, '');
        } else {
          entity.setAttribute(key, compValues);
        }
      });
    },
    disposeScene({ commit, getters }) {
      cleanupLocalMedia();
      if (getters.activeSceneEl) {
        getters.activeSceneEl.setAttribute('sr-dispose', '');
        getters.activeSceneEl.remove();
      }
      commit('resetSceneElRefs');
      commit('setActiveSceneEl', null);
      commit('setEnvironmentMediaFrames', []);
      commit('setSceneModels', []);
      commit('setScenePortals', []);
      commit('setSceneSeating', null);
      commit(RESET_GAME_SETTING_STATE, null, { root: true });
    }
  }
};
